.text-center {
  text-align: center;
}

.cursor-pointer {
  cursor: pointer;
}

.ft-w-400 {
  font-weight: 400;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-20 {
  width: 20%;
}

.w-max-60 {
  max-width: 60%;
}

/** padding top **/

.pt-1 {
  padding-top: 0.5rem;
}

.pt-2 {
  padding-top: 1.0rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pt-4 {
  padding-top: 2.0rem;
}

.pt-5 {
  padding-top: 2.5rem;
}

.m-0 {
  margin: 0;
}

.pl-1 {
  padding-left: 0.5rem;
}

/** margin top **/

.mt-1 {
  margin-top: 0.5rem;
}

.mt-2 {
  margin-top: 1.0rem;
}

.mt-3 {
  margin-top: 1.5rem;
}

.mt-4 {
  margin-top: 2.0rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-6 {
  margin-top: 3.0rem;
}

.mt-7 {
  margin-top: 3.5rem;
}

/** margin bottom **/

.mb-1 {
  margin-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 1.0rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-4 {
  margin-bottom: 2.0rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

/** margin left **/

.ml-1 {
  margin-left: 0.5rem;
}

.ml-2 {
  margin-left: 1.0rem;
}

.ml-3 {
  margin-left: 1.5rem;
}

.ml-4 {
  margin-left: 2.0rem;
}

.ml-5 {
  margin-left: 2.5rem;
}

/** margin right **/

.mr-0-5 {
  margin-right: 0.25rem;
}

.mr-1 {
  margin-right: 0.5rem;
}

.mr-2 {
  margin-right: 1.0rem;
}

.mr-3 {
  margin-right: 1.5rem;
}

.mr-4 {
  margin-right: 2.0rem;
}

.mr-5 {
  margin-right: 2.5rem;
}

.m-auto {
  margin: auto;
}