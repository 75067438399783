.custom-node-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #407EC970;
    width: 210px;
    height: 50px;
    
    .custom-handler {
        width: 14px;
        height: 20px;
        border-radius: 3px;
    }
}

@primary-color: #407EC9;@font-family: 'Open Sans', sans-serif;@typography-title-margin-top: 0;@typography-title-margin-bottom: 0;@typography-title-font-weight: 700;@btn-border-radius-base: 4px;@btn-default-color: #407EC9;@btn-default-bg: #FFFFFF;@btn-default-border: #407EC9;@btn-primary-color: #FFFFFF;@btn-disable-color: #FFFFFF;@btn-disable-bg: #EAEAEA;@btn-disable-border: #EAEAEA;@avatar-bg: #407EC9;@avatar-color: #FFFFFF;@table-padding-vertical: 10px;@table-padding-horizontal: 10px;@table-header-color: #FFFFFF;@table-header-bg: #959595;@layout-header-background: #0F3557;@layout-header-padding: 0 20px;@layout-body-background: #FFFFFF;@layout-header-height: unset;@checkbox-check-color: #FFFFFF;@border-color-base: #AAAAAA;@heading-1-size: 28px;@heading-2-size: 24px;@heading-3-size: 20px;@heading-4-size: 18px;@heading-5-size: 16px;@heading-color: #000000;