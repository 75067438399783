html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 0px;
  margin: 2px 0px;
}

::-webkit-scrollbar-thumb {
  background: #668EAD;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8fbbd9;
}
