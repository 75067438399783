h1.ant-typography, .ant-typography h1 {
  &.header-title {
    font-family: 'Bodoni MT';
    font-weight: 300;
  }
  font-weight: 600;
}

h3.ant-typography, .ant-typography h3 {
  font-weight: 700;
}

div.ant-typography, .ant-typography p {
  margin-bottom: 0 !important;
}